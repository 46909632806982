// .scrollbar{
//     -ms-overflow-style: none !important;  /* IE and Edge */
//     scrollbar-width: none !important;  
//   }
//   .scrollbar::-webkit-scrollbar {
//     display: none !important;
//   }
@media (max-width: 480px) {
    .scrollbar{
      -ms-overflow-style: none !important;  /* IE and Edge */
      scrollbar-width: none !important;  
    }
    .scrollbar::-webkit-scrollbar {
      display: none !important;
    }
  }