@import url("https://fonts.googleapis.com/css2?family=Manrope:wght@300;400;500&display=swap");

@keyframes zoomInAppear {
  from {
    opacity: 0;
    transform: scale(2.5);
  }
  to {
    opacity: 1;
    transform: scale(1);
  }
}

@media (max-width: 480px) {
  .scrollbar {
    -ms-overflow-style: none !important; /* IE and Edge */
    scrollbar-width: none !important;
  }
  .scrollbar::-webkit-scrollbar {
    display: none !important;
  }
}
