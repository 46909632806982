@import url(https://fonts.googleapis.com/css2?family=Manrope:wght@300;400;500&display=swap);
@-webkit-keyframes zoomInAppear{from{opacity:0;-webkit-transform:scale(2.5);transform:scale(2.5)}to{opacity:1;-webkit-transform:scale(1);transform:scale(1)}}@keyframes zoomInAppear{from{opacity:0;-webkit-transform:scale(2.5);transform:scale(2.5)}to{opacity:1;-webkit-transform:scale(1);transform:scale(1)}}@media(max-width: 480px){.scrollbar{-ms-overflow-style:none !important;scrollbar-width:none !important}.scrollbar::-webkit-scrollbar{display:none !important}}
.uploadmanager_managerContainer__3Uozx{position:fixed !important;right:10px !important;bottom:0 !important;width:400px !important;z-index:2}@media(max-width: 417px){.uploadmanager_managerContainer__3Uozx{position:fixed !important;bottom:0 !important;right:0 !important;width:100% !important;margin:auto}}
@media(max-width: 480px){.guided_scrollbar__19nzP{-ms-overflow-style:none !important;scrollbar-width:none !important}.guided_scrollbar__19nzP::-webkit-scrollbar{display:none !important}}
@media(max-width: 480px){.liveMeditation_scrollbar__1QoeY{-ms-overflow-style:none !important;scrollbar-width:none !important}.liveMeditation_scrollbar__1QoeY::-webkit-scrollbar{display:none !important}.liveMeditation_date__NVuxF{justify-content:flex-start}}@media(max-width: 620px){.liveMeditation_date__NVuxF{justify-content:flex-start !important}}
@media(max-width: 480px){.live_scrollbar__1s9dK{-ms-overflow-style:none !important;scrollbar-width:none !important}.live_scrollbar__1s9dK::-webkit-scrollbar{display:none !important}}
@media(max-width: 480px){.coaches_scrollbar__oSzuo{-ms-overflow-style:none !important;scrollbar-width:none !important}.coaches_scrollbar__oSzuo::-webkit-scrollbar{display:none !important}}
@media(max-width: 480px){.goals_scrollbar__R8AGn{-ms-overflow-style:none !important;scrollbar-width:none !important}.goals_scrollbar__R8AGn::-webkit-scrollbar{display:none !important}}
@media(max-width: 480px){.goalsv2_scrollbar__1LsgS{-ms-overflow-style:none !important;scrollbar-width:none !important}.goalsv2_scrollbar__1LsgS::-webkit-scrollbar{display:none !important}}
@media(max-width: 480px){.articles_scrollbar__p8KV4{-ms-overflow-style:none !important;scrollbar-width:none !important}.articles_scrollbar__p8KV4::-webkit-scrollbar{display:none !important}}
@media(max-width: 480px){.meditationwithpurpose_scrollbar__2BnNO{-ms-overflow-style:none !important;scrollbar-width:none !important}.meditationwithpurpose_scrollbar__2BnNO::-webkit-scrollbar{display:none !important}}
@media(max-width: 480px){.bhandaraEvents_scrollbar__29vID{-ms-overflow-style:none !important;scrollbar-width:none !important}.bhandaraEvents_scrollbar__29vID::-webkit-scrollbar{display:none !important}.bhandaraEvents_date__2vO_8{justify-content:flex-start}}@media(max-width: 620px){.bhandaraEvents_date__2vO_8{justify-content:flex-start !important}}
@media(max-width: 480px){.moods_scrollbar__hX2ih{-ms-overflow-style:none !important;scrollbar-width:none !important}.moods_scrollbar__hX2ih::-webkit-scrollbar{display:none !important}}
@media(max-width: 480px){.blogs_scrollbar__2OEmT{-ms-overflow-style:none !important;scrollbar-width:none !important}.blogs_scrollbar__2OEmT::-webkit-scrollbar{display:none !important}}
@media(max-width: 480px){.videos_scrollbar__3DnwY{-ms-overflow-style:none !important;scrollbar-width:none !important}.videos_scrollbar__3DnwY::-webkit-scrollbar{display:none !important}}
@media(max-width: 480px){.quotes_scrollbar__1HGJ6{-ms-overflow-style:none !important;scrollbar-width:none !important}.quotes_scrollbar__1HGJ6::-webkit-scrollbar{display:none !important}}
@media(max-width: 480px){.filters_scrollbar__3dbSn{-ms-overflow-style:none !important;scrollbar-width:none !important}.filters_scrollbar__3dbSn::-webkit-scrollbar{display:none !important}}
@media(max-width: 480px){.triggers_scrollbar__OD5tp{-ms-overflow-style:none !important;scrollbar-width:none !important}.triggers_scrollbar__OD5tp::-webkit-scrollbar{display:none !important}}
@media(max-width: 480px){.meditationbackground_scrollbar__3mhfc{-ms-overflow-style:none !important;scrollbar-width:none !important}.meditationbackground_scrollbar__3mhfc::-webkit-scrollbar{display:none !important}}
@media(max-width: 480px){.faq_scrollbar__2Rj4o{-ms-overflow-style:none !important;scrollbar-width:none !important}.faq_scrollbar__2Rj4o::-webkit-scrollbar{display:none !important}}
@media(max-width: 480px){.preRecordedVideos_scrollbar__1GsYy{-ms-overflow-style:none !important;scrollbar-width:none !important}.preRecordedVideos_scrollbar__1GsYy::-webkit-scrollbar{display:none !important}}
@media(max-width: 480px){.schedulePreRecordedVideo_scrollbar__14PVD{-ms-overflow-style:none !important;scrollbar-width:none !important}.schedulePreRecordedVideo_scrollbar__14PVD::-webkit-scrollbar{display:none !important}}
@media(max-width: 480px){.createLiveSession_scrollbar__QmNlT{-ms-overflow-style:none !important;scrollbar-width:none !important}.createLiveSession_scrollbar__QmNlT::-webkit-scrollbar{display:none !important}.createLiveSession_date__bLlCR{justify-content:flex-start}}@media(max-width: 620px){.createLiveSession_date__bLlCR{justify-content:flex-start !important}}
@media(max-width: 480px){.assignLiveSession_scrollbar__3CkxC{-ms-overflow-style:none !important;scrollbar-width:none !important}.assignLiveSession_scrollbar__3CkxC::-webkit-scrollbar{display:none !important}.assignLiveSession_date__38ZMZ{justify-content:flex-start}}@media(max-width: 620px){.assignLiveSession_date__38ZMZ{justify-content:flex-start !important}}
@media(max-width: 480px){.articles_scrollbar__2h-Q1{-ms-overflow-style:none !important;scrollbar-width:none !important}.articles_scrollbar__2h-Q1::-webkit-scrollbar{display:none !important}}
@media(max-width: 480px){.videos_scrollbar__16Dpp{-ms-overflow-style:none !important;scrollbar-width:none !important}.videos_scrollbar__16Dpp::-webkit-scrollbar{display:none !important}}
@media(max-width: 480px){.stories_scrollbar__25BBm{-ms-overflow-style:none !important;scrollbar-width:none !important}.stories_scrollbar__25BBm::-webkit-scrollbar{display:none !important}}
@media(max-width: 480px){.books_scrollbar__2iB9w{-ms-overflow-style:none !important;scrollbar-width:none !important}.books_scrollbar__2iB9w::-webkit-scrollbar{display:none !important}}
@media(max-width: 480px){.donationList_scrollbar__1pPQT{-ms-overflow-style:none !important;scrollbar-width:none !important}.donationList_scrollbar__1pPQT::-webkit-scrollbar{display:none !important}}
@media(max-width: 480px){.pdfs_scrollbar__20Aa1{-ms-overflow-style:none !important;scrollbar-width:none !important}.pdfs_scrollbar__20Aa1::-webkit-scrollbar{display:none !important}}
@media(max-width: 480px){.heartsappVideos_scrollbar__3ohur{-ms-overflow-style:none !important;scrollbar-width:none !important}.heartsappVideos_scrollbar__3ohur::-webkit-scrollbar{display:none !important}}
@media(max-width: 480px){.daajiMessage_scrollbar__dySyj{-ms-overflow-style:none !important;scrollbar-width:none !important}.daajiMessage_scrollbar__dySyj::-webkit-scrollbar{display:none !important}.daajiMessage_date__25pOI{justify-content:flex-start}}@media(max-width: 620px){.daajiMessage_date__25pOI{justify-content:flex-start !important}}
@media(max-width: 480px){.yatraGarden_scrollbar__3S0KX{-ms-overflow-style:none !important;scrollbar-width:none !important}.yatraGarden_scrollbar__3S0KX::-webkit-scrollbar{display:none !important}.yatraGarden_date__2k57b{justify-content:flex-start}}@media(max-width: 620px){.yatraGarden_date__2k57b{justify-content:flex-start !important}}
@media(max-width: 480px){.task_scrollbar__3N_it{-ms-overflow-style:none !important;scrollbar-width:none !important}.task_scrollbar__3N_it::-webkit-scrollbar{display:none !important}}
@media(max-width: 480px){.theme_scrollbar__1R-lH{-ms-overflow-style:none !important;scrollbar-width:none !important}.theme_scrollbar__1R-lH::-webkit-scrollbar{display:none !important}}
@media(max-width: 480px){.homeBanner_scrollbar__1jZWs{-ms-overflow-style:none !important;scrollbar-width:none !important}.homeBanner_scrollbar__1jZWs::-webkit-scrollbar{display:none !important}.homeBanner_date__3w4KP{justify-content:flex-start}}@media(max-width: 620px){.homeBanner_date__3w4KP{justify-content:flex-start !important}}
@media(max-width: 480px){.cards_scrollbar__Sq0we{-ms-overflow-style:none !important;scrollbar-width:none !important}.cards_scrollbar__Sq0we::-webkit-scrollbar{display:none !important}}
@media(max-width: 480px){.campaigns_scrollbar__2pQjf{-ms-overflow-style:none !important;scrollbar-width:none !important}.campaigns_scrollbar__2pQjf::-webkit-scrollbar{display:none !important}}
@media(max-width: 480px){.scrollbar{-ms-overflow-style:none !important;scrollbar-width:none !important}.scrollbar::-webkit-scrollbar{display:none !important}}
