@media (max-width: 480px) {
  .scrollbar{
    -ms-overflow-style: none !important;  /* IE and Edge */
    scrollbar-width: none !important;  
  }
  .scrollbar::-webkit-scrollbar {
    display: none !important;
  }
  .date{
  justify-content: flex-start;
  }
}
@media (max-width: 620px) {
  .date{
  justify-content: flex-start !important;
  }
}

