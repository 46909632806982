.managerContainer {
  position: fixed !important;
  right: 10px !important;
  bottom: 0 !important;
  width: 400px !important;
  z-index: 2;
}
@media (max-width: 417px) {
  .managerContainer {
    position: fixed !important;
    bottom: 0 !important;
    right: 0 !important;
    width: 100% !important;
    margin: auto;
  }
}
